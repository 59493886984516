export const environment = {
  production: true,
  serverUrl: 'https://getquote.letsinsure.ca',
  firebaseConfig: {
    apiKey: "AIzaSyA60cOH401UDISXuFMTz1lHp6j_xBS2Uxo",
    authDomain: "final-expense-on.firebaseapp.com",
    databaseURL: "https://final-expense-on-default-rtdb.firebaseio.com",
    projectId: "final-expense-on",
    storageBucket: "final-expense-on.appspot.com",
    messagingSenderId: "75283513305",
    appId: "1:75283513305:web:fd9c69c8ab2d94d6f1a3c8",
    measurementId: "G-MDVBRH80CN"
  }
};