import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()

export class MainComponentService {
  $selectedIndex = new BehaviorSubject<number>(0);

  $showBasicInfoSecondForm = new BehaviorSubject<any>(false);

  $coverageTermBehaviorSubject = new BehaviorSubject<any>('');
  $age = new BehaviorSubject<number>(0);

  $gqSuccess = new BehaviorSubject<boolean>(false);

  $protectionTypeFormData = new BehaviorSubject<any>('');
  $basicInfoFormOneData = new BehaviorSubject<any>('');
  $basicInfoFormTwoData = new BehaviorSubject<any>('');
  $selectedProvider = new BehaviorSubject<any>('');

  $defaultSettings = new BehaviorSubject<any>({
    activeProduct: '',
    insuranceType: '',
    minCoverage: 10,
    maxCoverage: 1000,
    coverageStep: 0,
    sliderValue: 100,
    selectedTerm: 0,
    activeTerm: '',
    enabledTermNumber: 0
  });
  constructor() {
  }

  setIndex(i: number): void {
    this.$selectedIndex.next(i);
  }
  setShowBasicInfoSecondForm(value: boolean): void {
    this.$showBasicInfoSecondForm.next(value);
  }
  getShowBasicInfoSecondForm(): boolean {
    return this.$showBasicInfoSecondForm.value;
  }
  setCoverageTerm(term: any): void {
    this.$coverageTermBehaviorSubject.next(term);
  }
  setAge(age: number): void {
    this.$age.next(age);
  }
  setGqSuccess(value: boolean): void {
    this.$gqSuccess.next(value);
  }
  getGqSuccess(): boolean {
    return this.$gqSuccess.value;
  }

  setProtectionTypeFormData(data: any): void {
    this.$protectionTypeFormData.next(data);
  }
  getProtectionTypeFormData(): any {
    return this.$protectionTypeFormData.value;
  }
  setBasicInfoFormOneData(data: any): void {
    this.$basicInfoFormOneData.next(data);
  }
  getBasicInfoFormOneData(): any {
    return this.$basicInfoFormOneData.value;
  }
  setBasicInfoFormTwoData(data: any): void {
    this.$basicInfoFormTwoData.next(data);
  }
  getBasicInfoFormTwoData(): any {
    return this.$basicInfoFormTwoData.value;
  }
  setSelectedProvider(data: any): void {
    this.$selectedProvider.next(data);
  }
  getSelectedProvider(): any {
    return this.$selectedProvider.value;
  }
  setDefaultSettings(data: any): void {
    this.$defaultSettings.next(data);
  }
  getDefaultSettings(): any {
    return this.$defaultSettings.value;
  }
}
