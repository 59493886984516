import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AppLiteComponentService {
  $selectedIndex = new BehaviorSubject<number>(0);
  $beneficiaryAge = new BehaviorSubject<number>(0);

  $healthFormData = new BehaviorSubject<any>(null);
  $beneficiaryFormData = new BehaviorSubject<any>(null);
  $insuranceFormData = new BehaviorSubject<any>(null);
  $generalInfoFormData = new BehaviorSubject<any>(null);

  $medicationList = new BehaviorSubject<any>([]);
  $heightUnit = new BehaviorSubject<any>('');
  $weightUnit = new BehaviorSubject<any>('');
  constructor() {
  }
  setIndex(index: number): void {
    this.$selectedIndex.next(index);
  }
  setBeneficiaryAge(age: number): void {
    this.$beneficiaryAge.next(age);
  }
  setHealthData(data: any): void {
    this.$healthFormData.next(data);
  }
  setBeneficiaryData(data: any): void {
    this.$beneficiaryFormData.next(data);
  }
  setInsuranceData(data: any): void {
    this.$insuranceFormData.next(data);
  }
  setGeneralInfoData(data: any): void {
    this.$generalInfoFormData.next(data);
  }
  getHealthData(): any {
    return this.$healthFormData.value;
  }
  getBeneficiaryData(): any {
    return this.$beneficiaryFormData.value;
  }
  getInsuranceData(): any {
    return this.$insuranceFormData.value;
  }
  getGeneralInfoData(): any {
    return this.$generalInfoFormData.value;
  }
  getMedicationList(): any {
    return this.$medicationList.value;
  }
  getHeightUnit(): any {
    return this.$heightUnit.value;
  }
  getWeightUnit(): any {
    return this.$weightUnit.value;
  }
}
