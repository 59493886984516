import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BroadcastEvent} from '../models/broadcaster-event';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class BroadcasterService {
  private eventBus: BehaviorSubject<BroadcastEvent>;

  constructor() {
    this.eventBus = new BehaviorSubject<BroadcastEvent>({key: '', data: ''});
  }

  broadcast(key: string, data?: any): any {
    this.eventBus.next({key, data});
  }

  on<T>(key: string): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data as T));
  }
}

export class BroadCasterEvents {
  static ReachedQuotes = 'Reached Last Step Of Get Quotes';
  static CheckAvailabilityOfData = 'Routing Back To Get Quotes From eApp';
  static ProtectionTypeChanged = 'Helpful when going back to protection type and selecting new one';
  static ProtectionTypeSelected = 'For changing the query params';
  static PreFillData = 'Fill all data when routing back to GetQuotes from eApp';
  static SaveAppLiteData = 'Save all values';
}
