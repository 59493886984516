import {NgModule} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';

const components = [
  HeaderComponent
];

@NgModule({
  imports: [],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})

export class SharedModule {}
