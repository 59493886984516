import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()

export class NeedsHelpService {
  $needsHelp = new BehaviorSubject<any>(undefined);

  constructor() {
  }
  setNeedsHelp(val: boolean): void {
    this.$needsHelp.next(val);
  }
  getNeedsHelp(): boolean {
    return this.$needsHelp.value;
  }
}
