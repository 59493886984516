import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // { path: 'funeral', loadChildren: () => import('./modules/funeral/funeral.module').then(m => m.FuneralModule) },
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ]
  }
  // { path: '', redirectTo: "dashboard", pathMatch: "full" },
  // { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  // { path: 'funeral', loadChildren: () => import('./modules/funeral/funeral.module').then(m => m.FuneralModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
