import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient) { }
  API_URL: string = environment.serverUrl;
  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error.error);
  }

  getProducts(data: any): any {
    const url = this.API_URL + '/getProducts';
    return this.http.post<any>(url, data)
      .pipe(
        tap(data1 => {
          return data1;
        }, error => {
          return error;
        }),
        catchError(ProductService.handleError)
      );
  }

  getMinimumPremium(data: any): any {
    const url = this.API_URL + '/getMinimumPremium';
    return this.http.post<any>(url, data)
      .pipe(
        tap(data1 => {
          return data1;
        }, error => {
          return error;
        }),
        catchError(ProductService.handleError)
      );
  }
  sendEmail(data: any): any {
    const url = this.API_URL + '/sendEmail';
    return this.http.post<any>(url, data)
      .pipe(
        tap(data1 => {
          return data1;
        }, error => {
          return error;
        }),
        catchError(ProductService.handleError)
      );
  }
}
