import {NgModule} from '@angular/core';
import {NeedsHelpService} from './services/needs-help.service';
import {MainComponentService} from './services/main-component.service';
import {ProductService} from './services/product.service';
import {BroadcasterService} from './services/broadcaster.service';
import {AppLiteComponentService} from './services/app-lite-component.service';

const services = [
  NeedsHelpService,
  MainComponentService,
  ProductService,
  BroadcasterService,
  AppLiteComponentService
];

@NgModule({
  declarations: [],
  providers: [...services]
})

export class CoreModule {}
